import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import {
  Form,
  Input,
  DatePicker,
  Select,
  Radio,
  Row,
  Col,
  Checkbox,
  Collapse,
} from 'antd';
import { useHistory } from 'react-router-dom';

import InputPhone from '../../../components/InputPhone';
import UploadFiles from '../../../components/UploadFiles';
import Button from '../../../components/Button';
import DebounceSelect from '../../../components/DebounceSelect';
import {
  optionOccurencePlace,
  optionReportedChannel,
  dateToFormat,
} from '../../../utils';

import Style from './style';
import {
  getIncidents,
  getSeverityLevels,
  getProblemTypes,
  getProvinces,
} from '../../../service';

const FormData = (props) => {
  const { onSubmit, onCancle } = props;
  const [form] = Form.useForm();
  const history = useHistory();
  const [customerType, setCustomerType] = React.useState('new'); // new, relate
  const [step, setStep] = React.useState('contact_crime_suppression');
  const [problemTypes, setProblemTypes] = React.useState([]);
  const [severityLevels, setSeverityLevels] = React.useState([]);
  const [provinces, setProvinces] = React.useState([]);

  const initPage = async () => {
    try {
      const resultSeverity = await getSeverityLevels();
      const resultProblemType = await getProblemTypes();
      const res = await getProvinces();
      const values = map(res.data, (obj) => ({
        label: obj.provinceName,
        value: obj.provinceCode,
      }));
      setProvinces(orderBy(values, ['value'], ['asc']));
      setProblemTypes(
        map(get(resultProblemType, 'data', {}), (obj) => {
          return {
            label: obj.problemType,
            value: obj.id,
          };
        })
      );
      setSeverityLevels(
        map(get(resultSeverity, 'data', {}), (obj) => {
          return {
            label: obj.severityLevel,
            value: obj.id,
          };
        })
      );
    } catch (err) {
      console.log('loadData err', err);
    }
  };

  React.useEffect(() => {
    initPage();
  }, []); // eslint-disable-line

  const searchIncidents = async (keyword = '') => {
    try {
      const body = {
        offset: 0,
        limit: 20,
        keyword,
      };
      const res = await getIncidents(body);
      console.log('res >>', res);
      return get(res, 'data', []).map((data) => {
        return {
          value: data.id,
          label: `#${data.caseNumber} ${data.firstName} ${data.lastName} / ${data.telephone} `,
        };
      });
    } catch (err) {
      console.log('loadData err', err);
      return [];
    }
  };

  const onFinish = (values) => {
    const create = {
      detail: get(values, 'detail', ''),
      files: get(values, 'files', []),
      firstName: get(values, 'firstName', ''),
      lastName: get(values, 'lastName', ''),
      gender: get(values, 'gender', ''),
      telephone: get(values, 'telephone', ''),
      address: get(values, 'address', ''),
      occurencePeriodFrom: dateToFormat(get(values, 'occurencePeriodFrom', '')),
      occurencePeriodTo: dateToFormat(get(values, 'occurencePeriodTo', '')),
      occurencePlace: get(values, 'occurencePlace', ''),
      reportedChannel: get(values, 'reportedChannel', ''),
      provinceCode: get(values, 'province', ''),
      incident: get(values, 'incident', ''),
      contacts: map(get(values, 'relates', []), (relate) => {
        return {
          contactFirstName: get(relate, 'contactFirstName', ''),
          contactLastName: get(relate, 'contactLastName', ''),
          contactTelephone: get(relate, 'contactTelephone', ''),
          contactRelation: get(relate, 'contactRelation', ''),
          contactAddress: get(relate, 'contactAddress', ''),
        };
      }),
    };
    let action;
    if (
      step === 'contact_crime_suppression' &&
      (get(values, 'requirePhoneNumber') !== undefined ||
        get(values, 'requireAddress') !== undefined)
    ) {
      action = {
        action: step,
        data: {
          requirePhoneNumber: values.requirePhoneNumber,
          requireAddress: values.requireAddress,
          requireContact: values.requireContact,
        },
      };
    }
    if (
      step === 'risk_assessment' &&
      (!isEmpty(values.severityLevelId) ||
        !isEmpty(values.problemTypeIds) ||
        !isEmpty(values.detail))
    ) {
      action = {
        action: step,
        data: {
          severityLevelId: values.severityLevelId,
          problemTypeIds: values.problemTypeIds,
          detail: values.risk_detail,
          suggestion: values.suggestion,
        },
      };
    }

    onSubmit({ create, action });
  };

  const handleCancle = async () => {
    onCancle();
  };

  return (
    <Style>
      <div className="title">สำหรับเจ้าหน้าที่ (Verify Case)</div>
      <Form
        layout="vertical"
        form={form}
        name="form-incidents"
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          gender: 'other',
          relates: [
            {
              contactFirstName: '',
              contactLastName: '',
              contactTelephone: '',
              contactRelation: '',
              contactAddress: '',
            },
          ],
        }}
      >
        <section className="info">
          <h2>ข้อมูลเบื้องต้น</h2>
          <div className="content-section">
            <Row>
              <Col md={8}>
                <Form.Item label="เวลาที่เกิดเหตุ" name="occurencePeriodFrom">
                  <DatePicker format="DD/MM/YYYY HH:mm" showTime />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  label="สิ้นสุดเวลาที่เกิดเหตุ"
                  name="occurencePeriodTo"
                >
                  <DatePicker format="DD/MM/YYYY HH:mm" showTime />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item label="สถานที่เกิดเหตุ" name="occurencePlace">
                  <Select options={optionOccurencePlace} />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item label="ช่องทางการแจ้งเตือน" name="reportedChannel">
                  <Select options={optionReportedChannel} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="รายละเอียด"
              name="detail"
              rules={[{ required: true, message: 'ระบุรายละเอียด' }]}
            >
              <Input.TextArea rows={4} placeholder="รายละเอียด" />
            </Form.Item>
            <Form.Item label="ภาพ/วิดีโอที่เกี่ยวข้อง" name="files">
              <UploadFiles />
            </Form.Item>
          </div>
        </section>
        <section className="border">
          <h2>ข้อมูลผู้มีความเสี่ยง</h2>
          <div className="select-type">
            <Radio.Group
              value={customerType}
              onChange={(e) => {
                setCustomerType(e.target.value);
              }}
            >
              <Radio value="new">เพิ่มข้อมูลใหม่</Radio>
              <Radio value="relate">เชื่อมจากข้อมูล</Radio>
            </Radio.Group>
          </div>
          <div className="content-section">
            {customerType === 'new' && (
              <Row>
                <Col md={12}>
                  <Form.Item label="ชื่อ ผู้มีความเสี่ยง" name="firstName">
                    <Input placeholder="ชื่อ" />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item label="นามสกุล ผู้มีความเสี่ยง" name="lastName">
                    <Input placeholder="นามสกุล" />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item label="เพศ" name="gender">
                    <Radio.Group>
                      <Radio value="male">ชาย</Radio>
                      <Radio value="female">หญิง</Radio>
                      <Radio value="other">
                        อื่นๆ ___
                        {/* {value === 4 ? <Input style={{ width: 100, marginLeft: 10 }} /> : null} */}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    label="เบอร์โทรศัพท์มือถือ ผู้มีความเสี่ยง"
                    name="telephone"
                  >
                    <InputPhone />
                  </Form.Item>
                </Col>
                <Col md={24}>
                  <Form.Item label="ที่อยู่" name="address">
                    <Input.TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item label="จังหวัด" name="province">
                    <Select
                      options={provinces}
                      showSearch
                      size="large"
                      filterOption={(input, option) => {
                        return (option?.label ?? '').includes(input);
                      }}
                      placeholder="ค้นหาผู้ป่วย"
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {customerType === 'relate' && (
              <Form.Item label="เลือกผู้ป่วย" name="incident">
                <DebounceSelect
                  fetchOptions={searchIncidents}
                  placeholder="Select users"
                />
              </Form.Item>
            )}
          </div>
        </section>
        <section className="relates">
          <h2>ข้อมูลผู้มีความเกี่ยวข้อง</h2>
          <div className="content-section">
            <Form.List name="relates">
              {(fields, { add, remove }) => (
                <div className="wrap-relates">
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div className="relate">
                      <Row>
                        <Col md={8}>
                          <Form.Item
                            {...restField}
                            name={[name, 'contactFirstName']}
                            label="ชื่อผู้เกี่ยวข้อง"
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col md={8}>
                          <Form.Item
                            {...restField}
                            name={[name, 'contactLastName']}
                            label="นามสกุลผู้เกี่ยวข้อง"
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col md={8}>
                          <Form.Item
                            {...restField}
                            name={[name, 'contactTelephone']}
                            label="เบอร์โทรศัพท์มือถือ ผู้มีความเสี่ยง"
                          >
                            <InputPhone />
                          </Form.Item>
                        </Col>
                        <Col md={8}>
                          <Form.Item
                            {...restField}
                            name={[name, 'contactRelation']}
                            label="ความเกี่ยวข้อง"
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col md={16}>
                          <Form.Item
                            {...restField}
                            name={[name, 'contactAddress']}
                            label="ที่อยู่"
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      {index > 0 && (
                        <div className="wrap-button-remove">
                          <Button
                            type="button"
                            onClick={() => remove(name)}
                            className="button-with-icon remove"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0L284.2 0c12.1 0 23.2 6.8 28.6 17.7L320 32l96 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 96C14.3 96 0 81.7 0 64S14.3 32 32 32l96 0 7.2-14.3zM32 128l384 0 0 320c0 35.3-28.7 64-64 64L96 512c-35.3 0-64-28.7-64-64l0-320zm96 64c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16z" />
                            </svg>
                            ลบ
                          </Button>
                        </div>
                      )}
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      onClick={() => add()}
                      type="button"
                      primary
                      className="button-with-icon"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19 12.998H13V18.998H11V12.998H5V10.998H11V4.99805H13V10.998H19V12.998Z"
                          fill="white"
                        />
                      </svg>
                      เพิ่มผู้เกี่ยวข้อง
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List>
          </div>
        </section>
        <section className="officer">
          <ul>
            <li
              className={step === 'contact_crime_suppression' ? 'active' : ''}
            >
              <button
                type="button"
                onClick={() => setStep('contact_crime_suppression')}
              >
                {step === 'contact_crime_suppression' ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                  >
                    <path
                      d="M24.8172 13.0001C24.8172 19.5921 19.4838 24.9328 12.9086 24.9328C6.33342 24.9328 1 19.5921 1 13.0001C1 6.40807 6.33342 1.06738 12.9086 1.06738C19.4838 1.06738 24.8172 6.40807 24.8172 13.0001Z"
                      fill="#FF7A44"
                      stroke="white"
                      strokeWidth="2"
                    />
                    <path
                      d="M18.5559 7.61475L10.488 18.9308L6.4541 14.0811"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="26"
                    viewBox="0 0 27 26"
                    fill="none"
                  >
                    <circle
                      cx="13.2435"
                      cy="12.9998"
                      r="11.91"
                      fill="white"
                      stroke="#C4C4C4"
                      strokeWidth="2"
                    />
                  </svg>
                )}
                ติดต่อกองปราบฯ เพื่อหาข้อมูล
              </button>
            </li>
            <li className={step === 'risk_assessment' ? 'active' : ''}>
              <button type="button" onClick={() => setStep('risk_assessment')}>
                {step === 'risk_assessment' ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                  >
                    <path
                      d="M24.8172 13.0001C24.8172 19.5921 19.4838 24.9328 12.9086 24.9328C6.33342 24.9328 1 19.5921 1 13.0001C1 6.40807 6.33342 1.06738 12.9086 1.06738C19.4838 1.06738 24.8172 6.40807 24.8172 13.0001Z"
                      fill="#FF7A44"
                      stroke="white"
                      strokeWidth="2"
                    />
                    <path
                      d="M18.5559 7.61475L10.488 18.9308L6.4541 14.0811"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="26"
                    viewBox="0 0 27 26"
                    fill="none"
                  >
                    <circle
                      cx="13.2435"
                      cy="12.9998"
                      r="11.91"
                      fill="white"
                      stroke="#C4C4C4"
                      strokeWidth="2"
                    />
                  </svg>
                )}
                ดำเนินการประเมินความเสี่ยง
              </button>
            </li>
            <li>
              <button type="button" onClick={handleCancle}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="26"
                  viewBox="0 0 27 26"
                  fill="none"
                >
                  <circle
                    cx="13.2435"
                    cy="12.9998"
                    r="11.91"
                    fill="white"
                    stroke="#C4C4C4"
                    strokeWidth="2"
                  />
                </svg>
                ยุติการให้คำปรึกษา
              </button>
            </li>
          </ul>
          <div className="content">
            {step === 'contact_crime_suppression' && (
              <section className="contact-crime-suppression">
                <h3>ติดต่อกองปราบฯ </h3>
                <Form.Item name="requirePhoneNumber" valuePropName="checked">
                  <Checkbox>เบอร์โทรศัพท์ติดต่อ</Checkbox>
                </Form.Item>

                <Form.Item name="requireAddress" valuePropName="checked">
                  <Checkbox>ที่อยู่/สถานที่เกิดเหตุ</Checkbox>
                </Form.Item>
                <Form.Item name="requireContact" valuePropName="checked">
                  <Checkbox>เบอร์โทรศัพท์ติดต่อญาติ</Checkbox>
                </Form.Item>
              </section>
            )}
            {step === 'risk_assessment' && (
              <section className="risk-assessment">
                <h3>ดำเนินการประเมินความเสี่ยง</h3>
                <Collapse>
                  <Collapse.Panel header="ประเมินอาการ" key="1">
                    <h3>รายละเอียดผู้ขอรับบริการ</h3>
                    <Row>
                      <Col md={12}>
                        <Form.Item
                          label="ประเภทกลุ่มปัญหา"
                          name="problemTypeIds"
                        >
                          <Select mode="multiple" options={problemTypes} />
                        </Form.Item>
                        <Form.Item
                          label="ระดับความเสี่ยง"
                          name="severityLevelId"
                        >
                          <Select options={severityLevels} />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item label="รายละเอียด" name="risk_detail">
                          <Input.TextArea rows={6} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <hr />
                    <h3>ข้อเสนอแนะ</h3>
                    <Row>
                      <Col md={12}>
                        <Form.Item label="รายละเอียด" name="suggestion">
                          <Input.TextArea rows={4} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </section>
            )}
          </div>
        </section>
        <div className="wrap-button">
          <Button
            size="lg"
            onClick={() => {
              history.push('/incidents/me');
            }}
          >
            ยกเลิก
          </Button>
          <Button type="submit" size="lg" primary>
            บันทึก
          </Button>
        </div>
      </Form>
    </Style>
  );
};

FormData.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancle: PropTypes.func.isRequired,
};

FormData.defaultProps = {};

export default FormData;
